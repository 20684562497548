import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Icon from "../utilities/icons"
import { SideInfoBox } from "../components/styles/SideInfoBox"

const ContactForm = styled.form`
  background: #fff;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;

  label {
    font-size: 12px;
    display: block;
    width: 100%;
  }
  span {
    margin-bottom: 0;
    margin-top: 10px;
    display: block;
    line-height: 16px;
  }
  input,
  textarea {
    font-size: 16px;
    display: block;
    width: 100%;
    background: #f8fafc;
    outline: 0;
    padding: 8px;
    box-shadow: 0;
    border: 1px solid #d9d9d9;
    font-weight: 200;
  }
  textarea {
    height: 150px;
  }
  button {
    margin-top: 20px;
    display: block;
    text-decoration: none;
    border: none;
  }
`

const ContactExtraInfoBlock = styled.p`
  background: #f6e05e;
  color: #333;
  padding: 10px;
  font-size: 14px;
`

const ContactPage = props => {
  const [values, setValues] = useState({})
  const { location } = props

  const handleInputChange = event => {
    event.persist()

    //const target = event.target
    //const value = target.value
    //const name = target.name

    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  {
    console.dir("props")
  }
  {
    console.dir(props.location.state)
  }

  //TODO SAVE TO LOCAL STORAGE IN CASE OF NAVIGATION AWAY

  //TODO ALLOW CLOSE ON LASH LIFT TO CANCEL TREATMENT

  return (
    <Layout>
      <SEO
        title="Contact |  MJ Electrical | Electrician in Newark"
        description="Contact MJ Electrical - Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
      />
      <div class="standard-page-container">
        <div className="inner-container">
          <h1>Contact</h1>

          <p>
            Please use the contact form below to get in touch. We will do our
            best to reply as soon as possible. Alternatively you can call us on{" "}
            <b>07963 168251</b>.
          </p>
          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide ">
              <ContactForm
                data-netlify="true"
                method="post"
                action=""
                name="contact"
                className="global-box-shadow"
              >
                <input type="hidden" name="form-name" value="contact" />

                <label>
                  <span>Name*</span>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    value={values.name}
                    required
                  />
                </label>
                <label>
                  <span>Email*</span>
                  <input
                    type="text"
                    name="email"
                    onChange={handleInputChange}
                    value={values.email}
                    required
                  />
                </label>
                <label>
                  <span>Phone No.</span>
                  <input
                    type="text"
                    name="phone"
                    onChange={handleInputChange}
                    value={values.phone}
                  />
                </label>
                <label>
                  <span>Message</span>
                  <textarea
                    name="message"
                    onChange={handleInputChange}
                    value={values.message}
                    required
                  />
                </label>
                <div className="global-btn-wrapper">
                  <button className="global-btn global-btn_blue" type="submit">
                    Submit
                  </button>
                </div>
              </ContactForm>
            </div>
            <div className="two-column-layout-item_narrow">
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Quick Contact</h3>
                <ul className="contact-box-list">
                  <li>
                    <b>Phone / Text:</b> 07963 168251
                  </li>
                  <li>
                    <b>Email: </b>
                    <a href="mailto:info@mjelectric.co.uk">
                      {" "}
                      info@mjelectric.co.uk
                    </a>
                  </li>
                  <li>
                    <b>Facebook: </b>
                    <a
                      target="_new"
                      href="https://www.facebook.com/mj.electrical1"
                    >
                      <Icon
                        name="facebook"
                        color="#3C5A99"
                        width="16"
                        height="16"
                      />
                    </a>
                  </li>
                </ul>
              </SideInfoBox>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
